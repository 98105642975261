import { getCurrentScope, applyScopeDataToEvent } from '@sentry/core';
import type { Event, EventHint, Integration, ScopeContext } from '@sentry/core';

export function sentryErrorIntegration(): Integration {
   return {
      name: 'SentryErrorIntegration',
      processEvent(event: Event, hint: EventHint) {
         const exception = hint.originalException;

         if (exception instanceof SentryError) {
            const currentScope = getCurrentScope();
            const newScope = currentScope.clone();

            newScope.update(exception.sentryDetails);
            applyScopeDataToEvent(event, newScope.getScopeData());
         }

         return event;
      },
   };
}

export class SentryError extends Error {
   constructor(
      message: string,
      readonly sentryDetails: Partial<ScopeContext> = {}
   ) {
      super(message);
   }
}
